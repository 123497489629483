// assets
import { IconApi, IconChartPie } from '@tabler/icons-react';

// constant
const icons = {
  IconChartPie,
  IconApi
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const convert = {
  id: 'convert',
  title: 'Project Settings',
  type: 'group',
  children: [
    {
      id: 'license-key',
      title: 'License Key',
      type: 'item',
      url: '/project/license-key',
      icon: icons.IconApi,
      breadcrumbs: false
    },
    {
      id: 'usage',
      title: 'Usage',
      type: 'item',
      url: '/project/usage',
      icon: icons.  IconChartPie,
      breadcrumbs: false
    }
  ]
};

export default convert;
