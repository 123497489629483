import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

import { supabase } from 'supabase/supabaseClient';
import Auth from 'supabase/Auth';

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);

  const [session, setSession] = useState(null);
  const [user, setUser] = useState(null);

  const getUser = async () => {
    const { data } = await supabase.auth.getUser();
    const userInfo = data?.user;
    // console.log('App getUser userInfo ' + JSON.stringify(userInfo));
    setUser(userInfo);
  };

  useEffect(() => {
    getUser();
    supabase.auth.getSession().then(({ data: { session } }) => {
      // console.log('App getSession session ' + session);
      // setSession(session);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      // console.log('App onAuthStateChange session ' + session);
      // console.log('App onAuthStateChange _event ' + _event);
      setSession(session);
    });
  }, []);

  return (
    <>
      {!session ? (
        <Auth />
      ) : (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themes(customization)}>
            <CssBaseline />
            <NavigationScroll>
              <Routes />
            </NavigationScroll>
          </ThemeProvider>
        </StyledEngineProvider>
      )}
    </>
  );
};

export default App;
